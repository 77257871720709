<template>
  <!-- Base Avatar -->
  <div
    class="base-avatar"
    :class="square ? 'rounded-md' : 'rounded-full'"
    :style="getAvatarStyle"
  >
    <!-- Placeholder -->
    <div
      v-if="!avatar"
      class="bg-black border border-white border-opacity-10 flex h-full items-center justify-center w-full rounded-full p-px"
    >
      <svg
        width="22"
        height="22"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        class="h-full w-full"
      >
        <path
          d="M4.88064 15.7625C5.9753 14.1634 7.77653 13.1243 9.99984 13.1243C12.2231 13.1243 14.0244 14.1634 15.119 15.7625M4.88064 15.7625C6.24202 16.9726 8.03508 17.7077 9.99984 17.7077C11.9646 17.7077 13.7577 16.9726 15.119 15.7625M4.88064 15.7625C3.29222 14.3505 2.2915 12.2918 2.2915 9.99935C2.2915 5.74215 5.74264 2.29102 9.99984 2.29102C14.257 2.29102 17.7082 5.74215 17.7082 9.99935C17.7082 12.2918 16.7075 14.3505 15.119 15.7625M12.7082 8.33268C12.7082 9.82845 11.4956 11.041 9.99984 11.041C8.50407 11.041 7.2915 9.82845 7.2915 8.33268C7.2915 6.83691 8.50407 5.62435 9.99984 5.62435C11.4956 5.62435 12.7082 6.83691 12.7082 8.33268Z"
          stroke="#ffffff"
          stroke-opacity="0.45"
          stroke-width="1.5"
          stroke-linejoin="round"
        />
      </svg>
    </div>

    <!-- Image -->
    <img
      v-else-if="!showError"
      :class="square ? 'rounded-md' : 'rounded-full'"
      :src="avatar"
      @error="showError = true"
      referrerpolicy="no-referrer"
    >

    <div
      v-else
      class="base-avatar--placeholder"
    >
      <i class="fas fa-unlink" />
    </div>
  </div>
</template>

<script>
export default {
  name: 'BaseAvatar',
  props: {
    // Avatar
    avatar: {
      type: String,
      required: false,
      default: ''
    },
    // Square
    square: {
      type: Boolean,
      default: false
    },
    // Size
    size: {
      type: [Number, String],
      required: false,
      default: 60
    }
  },
  data () {
    return {
      showError: false
    }
  },
  computed: {
    // Get Avatar Style
    getAvatarStyle () {
      return {
        height: this.size + 'px',
        width: this.size + 'px'
      }
    }
  }
}
</script>

<style scoped lang="sass">
.base-avatar
  @apply flex-shrink-0

  &--placeholder
    @apply bg-gray-400 border border-gray-400 flex h-full items-center justify-center text-white w-full

  img
    @apply border border-gray-200 h-full object-cover w-full
</style>
