<template>
  <div>
    <div
      class="w-full h-full rounded-t-3xl px-32 py-12 flex flex-col overflow-auto"
      style="height: calc(100vh - 136px)"
    >
      <!-- Header -->
      <div class="flex flex-col gap-1">
        <BaseText
          type="label"
          class="text-neutral-600"
        >
          Billing
        </BaseText>
        <BaseText
          size="sm"
          class="max-w-lg text-neutral-500"
        >
          View and edit your active Foreplay subscription. If you have any questions about plans, customer support will
          be happy to help you.
        </BaseText>
      </div>
      <!-- Divider -->
      <div class="border-t border-neutral-50 w-full my-7" />
      <!-- Address section -->
      <div class="grid grid-cols-12">
        <!-- Left column -->
        <div class="col-span-6 flex flex-col gap-1">
          <BaseText
            type="label"
            class="text-neutral-600"
          >
            Address
          </BaseText>
          <BaseText
            size="sm"
            class="max-w-xs text-neutral-500"
          >
            Billing address for your credit card and invoice.
          </BaseText>
        </div>
        <!-- Right Column -->
        <div class="col-span-6">
          <div class="grid grid-cols-6 grid-rows-3 gap-3">
            <!-- First row -->
            <!-- Street -->
            <div class="flex flex-col gap-1 w-full col-span-6">
              <BaseText
                type="label"
                size="sm"
                class="text-text-muted"
              >
                Street
              </BaseText>
              <div
                v-if="loading.address"
                class="min-w-0 w-full h-8 rounded-md px-2 py-1.5 skeleton"
              />
              <input
                v-else
                v-model="addressForm.street"
                :class="{ 'invalid': !loading.address && !addressForm.street && submitAttempted }"
                :disabled="loading.address || loading.addressSave"
                class="form-input fade-in min-w-0 w-full px-2 py-1.5"
              >
              <!-- invalid indicator -->
              <div
                v-if="!loading.address && !addressForm.street && submitAttempted"
                class="flex items-center gap-1"
              >
                <AltAlertIcon class="text-secondary-red-100" />
                <BaseText
                  type="body"
                  size="xs"
                  class="text-secondary-red-100"
                >
                  Required
                </BaseText>
              </div>
            </div>
            <!-- Second row -->
            <!-- City -->
            <div class="flex flex-col gap-1 w-full col-span-2">
              <BaseText
                type="label"
                size="sm"
                class="text-text-muted"
              >
                City
              </BaseText>
              <div
                v-if="loading.address"
                class="min-w-0 w-full h-8 rounded-md px-2 py-1.5 skeleton"
              />
              <input
                v-else
                v-model="addressForm.city"
                :disabled="loading.address || loading.addressSave"
                :class="{ 'invalid': !loading.address && !addressForm.city && submitAttempted }"
                class="form-input fade-in min-w-0 w-full px-2 py-1.5"
              >
              <!-- invalid indicator -->
              <div
                v-if="!loading.address && !addressForm.city && submitAttempted"
                class="flex items-center gap-1"
              >
                <AltAlertIcon class="text-secondary-red-100" />
                <BaseText
                  type="body"
                  size="xs"
                  class="text-secondary-red-100"
                >
                  Required
                </BaseText>
              </div>
            </div>
            <!-- State -->
            <div class="flex flex-col gap-1 w-full col-span-2">
              <BaseText
                type="label"
                size="sm"
                class="text-text-muted"
              >
                State
              </BaseText>
              <div
                v-if="loading.address"
                class="min-w-0 w-full h-8 rounded-md px-2 py-1.5 skeleton"
              />
              <input
                v-else
                v-model="addressForm.state"
                :disabled="loading.address || loading.addressSave"
                :class="{ 'invalid': !loading.address && !addressForm.state && submitAttempted }"
                class="form-input fade-in min-w-0 w-full px-2 py-1.5"
              >
              <!-- invalid indicator -->
              <div
                v-if="!loading.address && !addressForm.state && submitAttempted"
                class="flex items-center gap-1"
              >
                <AltAlertIcon class="text-secondary-red-100" />
                <BaseText
                  type="body"
                  size="xs"
                  class="text-secondary-red-100"
                >
                  Required
                </BaseText>
              </div>
            </div>
            <div class="flex flex-col gap-1 w-full col-span-2">
              <BaseText
                type="label"
                size="sm"
                class="text-text-muted"
              >
                Country
              </BaseText>
              <div
                v-if="loading.address"
                class="min-w-0 w-full h-8 rounded-md px-2 py-1.5 skeleton"
              />
              <input
                v-else
                v-model="addressForm.country"
                :disabled="loading.address || loading.addressSave"
                :class="{ 'invalid': !loading.address && !addressForm.country && submitAttempted }"
                class="form-input fade-in min-w-0 w-full px-2 py-1.5"
              >
              <!-- invalid indicator -->
              <div
                v-if="!loading.address && !addressForm.country && submitAttempted"
                class="flex items-center gap-1"
              >
                <AltAlertIcon class="text-secondary-red-100" />
                <BaseText
                  type="body"
                  size="xs"
                  class="text-secondary-red-100"
                >
                  Required
                </BaseText>
              </div>
            </div>
            <!-- Third row -->
            <div class="flex flex-col gap-1 w-full col-span-3">
              <BaseText
                type="label"
                size="sm"
                class="text-text-muted"
              >
                Postal/Zip Code
              </BaseText>
              <div
                v-if="loading.address"
                class="min-w-0 w-full h-8 rounded-md px-2 py-1.5 skeleton"
              />
              <input
                v-else
                v-model="addressForm.postal_code"
                :disabled="loading.address || loading.addressSave"
                :class="{ 'invalid': !loading.address && !addressForm.postal_code && submitAttempted }"
                class="form-input fade-in min-w-0 w-full px-2 py-1.5"
              >
              <!-- invalid indicator -->
              <div
                v-if="!loading.address && !addressForm.postal_code && submitAttempted"
                class="flex items-center gap-1"
              >
                <AltAlertIcon class="text-secondary-red-100" />
                <BaseText
                  type="body"
                  size="xs"
                  class="text-secondary-red-100"
                >
                  Required
                </BaseText>
              </div>
            </div>
            <div class="flex flex-col gap-1 w-full col-span-3">
              <BaseText
                type="label"
                size="sm"
                class="text-text-muted"
              >
                Tax ID
              </BaseText>
              <div
                v-if="loading.address"
                class="min-w-0 w-full h-8 rounded-md px-2 py-1.5 skeleton"
              />
              <input
                v-else
                v-model="addressForm.taxId"
                :disabled="loading.address || loading.addressSave"
                class="form-input fade-in min-w-0 w-full px-2 py-1.5"
              >
            </div>
          </div>
          <!-- Fourth row -->
          <BaseButton
            class="w-full mt-4"
            primary
            :loading="loading.addressSave"
            :disabled="!hasAddressChanged || loading.address"
            forced-theme="blue"
            @click="saveAddress"
          >
            Save Changes
          </BaseButton>
        </div>
      </div>
      <!-- Divider -->
      <div class="border-t border-neutral-50 w-full my-7" />
      <!-- Billing email -->
      <div class="grid grid-cols-12 w-full">
        <!-- Left column -->
        <div class="col-span-6 flex flex-col gap-1">
          <BaseText
            type="label"
            class="text-neutral-600"
          >
            Billing Email
          </BaseText>
          <BaseText
            size="sm"
            class="max-w-xs text-neutral-500"
          >
            Email address for receiving billing information.
          </BaseText>
        </div>
        <!-- Right column -->
        <div class="col-span-6">
          <!-- Email -->
          <div class="flex flex-col gap-1 w-full">
            <BaseText
              type="label"
              size="sm"
              class="text-text-muted"
            >
              Email
            </BaseText>
            <div class="flex gap-3 items-center">
              <div
                v-if="loading.address"
                class="min-w-0 w-full h-8 rounded-md px-2 py-1.5 skeleton"
              />
              <input
                v-else
                v-model="billingEmail"
                :disabled="loading.address || loading.emailSave"
                class="form-input fade-in min-w-0 w-full px-2 py-1.5"
                placeholder="you@example.com"
              >
              <BaseButton
                class="w-40"
                primary
                :loading="loading.emailSave"
                :disabled="loading.emailSave || !isEmailValid || !hasEmailChanged"
                forced-theme="blue"
                @click="saveBillingEmail"
              >
                Save Changes
              </BaseButton>
            </div>
          </div>
        </div>
      </div>
      <!-- Divider -->
      <div class="border-t border-neutral-50 w-full my-7" />
      <div
        v-if="!loading.invoices && hasInvalidPayment"
        class="w-full flex items-center gap-2 p-2 rounded-md mb-7 warning-banner"
      >
        <WarningIcon class="text-primary-red-300" />
        <BaseText
          type="label"
          size="sm"
          class="text-secondary-red-300"
        >
          Something is wrong with your payment method.
        </BaseText>
        <BaseText
          size="sm"
          class="text-secondary-red-300"
        >
          Update if you don't want to lose access to your account.
        </BaseText>
      </div>
      <!-- Payment method -->
      <div class="grid grid-cols-12 w-full">
        <!-- Left column -->
        <div class="col-span-6 flex flex-col gap-1">
          <BaseText
            type="label"
            class="text-neutral-600"
          >
            Payment Method
          </BaseText>
          <BaseText
            size="sm"
            class="max-w-xs text-neutral-500"
          >
            Add or change your billing method.
          </BaseText>
        </div>
        <!-- Right Column -->
        <div class="col-span-6 flex items-center justify-between">
          <div
            v-if="loading.card || (!loading.card && paymentMethod)"
            class="flex gap-4 items-center"
          >
            <!-- Card preview -->
            <div
              v-if="loading.card"
              class="w-14 h-14 bg-gray-200 animate-pulse rounded-xl"
            />
            <div
              v-else-if="!loading.card"
              class="border border-neutral-50 rounded-xl h-14 w-14 p-3"
            >
              <img
                :src="cardBrandAsset[paymentMethod.cardBrand]?.img"
                class="w-full h-full"
              >
            </div>
            <!-- Card info -->
            <div>
              <BaseText
                type="label"
                size="sm"
                class="text-text-muted"
              >
                <div
                  v-if="loading.card"
                  class="skeleton w-24 h-3 rounded-full"
                />
                <div v-else>
                  <span>{{ cardBrandAsset[paymentMethod.cardBrand].name }} </span><span>ending in {{ paymentMethod.last4
                  }}</span>
                </div>
              </BaseText>
              <BaseText
                size="sm"
                class="text-text-normal"
              >
                <div
                  v-if="loading.card"
                  class="skeleton h-3 rounded-full w-14 mt-1"
                />
                <div v-else>
                  Expires at {{ paymentMethod.expMonth }}/{{ paymentMethod.expYear }}
                </div>
              </BaseText>
            </div>
          </div>
          <div
            v-else
            class="flex items-start gap-2 fade-in"
          >
            <div class="relative w-10">
              <div class="absolute -top-1 -left-2 w-5 h-5 text-center bg-secondary-red-100 rounded-full text-white">
                !
              </div>
              <img
                class="w-14 h-auto"
                src="../../assets/images/payment-card.svg"
              >
            </div>
            <BaseText size="sm">
              No payment method found
            </BaseText>
          </div>
          <BaseButton
            outlined
            @click="showUpdatePaymentModal = true"
          >
            Update Card
          </BaseButton>
        </div>
      </div>
      <!-- Divider -->
      <div class="border-t border-neutral-50 w-full my-7" />
      <div class="w-full flex justify-between items-end">
        <div>
          <BaseText
            type="label"
            class="text-neutral-600"
          >
            Invoices
          </BaseText>
          <BaseText
            size="sm"
            class="max-w-xs text-neutral-500"
          >
            View and download your billing records.
          </BaseText>
        </div>
        <div class="flex">
          <BaseButton
            outlined
            class="zenvoice-button"
            @click="viewZenvoice"
          >
            <div class="flex items-center">
              <div class="zenvoice-logo w-8 h-8 p-1">
                <img
                  src="../../assets/images/zenvoice-logo.svg"
                  class="w-6 h-6"
                >
              </div>
              <div class="px-2.5">
                Edit and View All with Zenvoice
              </div>
            </div>
          </BaseButton>
        </div>
      </div>
      <!-- Invoice table -->
      <div class="w-full mt-4">
        <table class="min-w-full">
          <thead class="bg-neutral-25 text-neutral-500 uppercase">
            <tr>
              <th
                scope="col"
                class="relative p-2 text-left"
              >
                <span class="sr-only">Payment Status</span>
              </th>
              <th
                scope="col"
                class="p-2 text-left"
              >
                <BaseText
                  type="heading"
                  size="overline"
                >
                  Date
                </BaseText>
              </th>
              <th
                scope="col"
                class="p-2 text-left"
              >
                <BaseText
                  type="heading"
                  size="overline"
                >
                  Description
                </BaseText>
              </th>
              <th
                scope="col"
                class="p-2 text-left"
              >
                <BaseText
                  type="heading"
                  size="overline"
                >
                  Amount
                </BaseText>
              </th>
              <th
                scope="col"
                class="relative p-2 text-left"
              >
                <span class="sr-only">View receipt</span>
              </th>
            </tr>
          </thead>
          <tbody class="bg-white">
            <tr
              v-for="(invoice, index) in invoices"
              :key="index"
              class="text-neutral-600"
            >
              <td class="px-2 py-2.5 whitespace-nowrap">
                <!-- Paid -->
                <div
                  v-if="loading.invoices"
                  class="h-5 w-5 rounded-md bg-gray-200 animate-pulse"
                />
                <div v-else>
                  <SuccessIcon v-if="invoice.isPaid" />
                  <ErrorIcon v-else />
                </div>
              </td>
              <td class="px-2 py-2.5 whitespace-nowrap">
                <BaseText
                  type="label"
                  size="sm"
                >
                  <div
                    v-if="loading.invoices"
                    class="h-4 w-full rounded-full bg-gray-200 animate-pulse"
                  />
                  <div v-else>
                    {{ invoice.date }}
                  </div>
                </BaseText>
              </td>
              <td class="px-2 py-2.5 whitespace-nowrap">
                <BaseText size="sm">
                  <div
                    v-if="loading.invoices"
                    class="h-4 w-96 rounded-full bg-gray-200 animate-pulse"
                  />
                  <div v-else>
                    {{ invoice.description }}
                  </div>
                </BaseText>
              </td>
              <td class="px-2 py-2.5 whitespace-nowrap">
                <BaseText size="sm">
                  <div
                    v-if="loading.invoices"
                    class="h-4 w-20 rounded-full bg-gray-200 animate-pulse"
                  />
                  <div v-else>
                    {{ invoice.amount }}
                  </div>
                </BaseText>
              </td>
              <td class="px-2 py-2.5 whitespace-nowrap text-right">
                <div
                  v-if="loading.invoices"
                  class="h-4 w-20 rounded-full bg-gray-200 animate-pulse"
                />
                <a
                  v-else
                  :href="invoice.recieptUrl"
                  target="_blank"
                  class="cursor-pointer"
                  :style="{ cursor: invoice?.recieptUrl ? 'pointer' : 'auto', color: invoice?.recieptUrl ? 'black' : '#a4abb8' }"
                >View
                  Invoice</a>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <UpdatePaymentMethodModal
      v-if="showUpdatePaymentModal"
      @close="closePaymentModal"
    />
  </div>
</template>

<script>
import API from '@/api'
import { mapGetters } from 'vuex'
import moment from 'moment'
import _ from 'lodash'
import UpdatePaymentMethodModal from '@/components/account/subscription/UpdatePaymentMethodModal.vue'

// Icons
import SuccessIcon from '../globals/Icons/SuccessIcon.vue'
import ErrorIcon from '../globals/Icons/ErrorIcon.vue'
import WarningIcon from '../globals/Icons/WarningIcon.vue'
import AltAlertIcon from '../globals/Icons/AltAlertIcon.vue'
export default {
  name: 'BillingSettings',
  components: {
    UpdatePaymentMethodModal,
    // Icons
    SuccessIcon,
    ErrorIcon,
    WarningIcon,
    AltAlertIcon
  },
  data () {
    return {
      paymentMethod: {
        cardBrand: 'mastercard'
      },

      hasInvalidPayment: false,

      originalAddress: {},
      addressForm: {
        street: '',
        city: '',
        state: '',
        country: '',
        postal_code: '',
        taxId: ''
      },

      billingEmail: '',
      originalBillingEmail: '',

      invoices: [{}, {}, {}, {}],

      showUpdatePaymentModal: false,
      submitAttempted: false,
      // Initial load states
      loading: {
        invoices: true,
        card: true,
        address: true,
        addressSave: false,
        emailSave: false
      },

      // Statics
      cardBrandAsset: {
        visa: {
          img: require('../../assets/images/visa-dark.svg'),
          name: 'Visa'
        },
        mastercard: {
          img: require('../../assets/images/mastercard.svg'),
          name: 'Mastercard'
        },
        amex: {
          img: require('../../assets/images/americanexpress.svg'),
          name: 'American Express'
        }
      }
    }
  },
  computed: {
    ...mapGetters('AuthModule', ['getStripeCustomer', 'getUserInvoices']),
    hasAddressChanged () {
      if (this.loading.addressSave) return false
      return !_.isEqual(this.originalAddress, this.addressForm)
    },
    isEmailValid () {
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
      return emailRegex.test(this.billingEmail)
    },
    hasEmailChanged () {
      if (this.loadingEmailSave) return false
      return this.originalBillingEmail !== this.billingEmail
    },
    isFormValid () {
      return this.isEmailValid && this.addressForm.street && this.addressForm.city && this.addressForm.state && this.addressForm.country && this.addressForm.postal_code
    }
  },
  async mounted () {
    await this.getInvoices()
    await this.getCustomerBilling()
    await this.getCustomerPaymentMethod()
  },
  methods: {
    // Get billing address & email
    async getCustomerBilling () {
      const customerId = this.getStripeCustomer.stripeId

      const { address, taxId, email } = await API.Stripe.getCustomerAddress(customerId)
      const addressObj = { ...address, street: address?.line1, taxId: taxId }
      this.loading.address = false
      this.addressForm = { ...addressObj } || {}
      this.originalAddress = { ...addressObj } || {}

      this.originalBillingEmail = email
      this.billingEmail = email
    },
    async getCustomerPaymentMethod () {
      const customerId = this.getStripeCustomer.stripeId

      const { paymentMethod } = await API.Stripe.getCustomerPaymentMethod(customerId)
      this.paymentMethod = paymentMethod
      this.loading.card = false
    },
    async getInvoices () {
      const sortedInvoices = [...this.getUserInvoices].sort((a, b) => a.created < b.created ? 1 : -1).splice(0, 5)
      const invoicesWithReciepts = await Promise.all(sortedInvoices.map(async invoice => {
        const invoiceCopy = { ...invoice }
        if (invoiceCopy.charge) {
          const data = await API.Stripe.getHostedReciept(invoiceCopy.charge)
          invoiceCopy.recieptUrl = data.receipt_url
        }
        if (invoiceCopy.status !== 'paid') {
          this.hasInvalidPayment = true
        }
        const invoiceLines = invoiceCopy.lines.data
        // First line description is the flat rate $0.00 description, prioritize the price one
        const desc = invoiceLines.length > 1 ? invoiceLines[1].description : invoiceLines[0].description
        const formatDate = moment.unix(invoiceCopy.created).format('MM.DD.YYYY')
        return {
          description: desc,
          amount: this.formatMoney(invoiceCopy.amount_paid / 100),
          recieptUrl: invoiceCopy.recieptUrl,
          date: formatDate,
          isPaid: invoiceCopy.status === 'paid'
        }
      }))

      this.invoices = invoicesWithReciepts
      this.loading.invoices = false
    },
    formatMoney (amount) {
      const formatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD'
      })

      return formatter.format(amount)
    },
    async saveAddress () {
      this.submitAttempted = true
      if (!this.isFormValid) return
      this.loading.addressSave = true
      const addressToUpdate = {
        line1: this.addressForm.street,
        city: this.addressForm.city,
        state: this.addressForm.state,
        postal_code: this.addressForm.postal_code,
        country: this.addressForm.country
      }
      const taxId = this.addressForm.taxId
      try {
        await API.Stripe.updateAddress(this.getStripeCustomer.stripeId, addressToUpdate, taxId)
        this.originalAddress = { ...this.addressForm }
        this.$showAlert({
          message: 'Your address has been updated.',
          type: 'success'
        })
        this.loading.addressSave = false
        this.submitAttempted = false
      } catch (e) {
        this.$showAlert({ type: 'error', message: 'Invalid billing address, please verify your information' })
        this.loading.addressSave = false
        this.submitAttempted = false
      }
    },
    async saveBillingEmail () {
      if (!this.isEmailValid) return

      this.loading.emailSave = true
      try {
        await API.Stripe.updateBillingEmail(this.getStripeCustomer.stripeId, this.billingEmail)
        this.originalBillingEmail = this.billingEmail
        this.$showAlert({
          message: 'Your billing email has been updated.',
          type: 'success'
        })
      } catch (e) {
        this.$showAlert({
          message: 'Error updating billing email.',
          type: 'error'
        })
      }
      this.loading.emailSave = false
    },
    viewZenvoice () {
      const customerEmail = this.getStripeCustomer.email
      window.open(`https://zenvoice.io/p/65d3580c42a0dad9d67fe601?email=${customerEmail}`, '_blank')
    },
    async closePaymentModal () {
      this.showUpdatePaymentModal = false
      await this.getCustomerPaymentMethod()
    }
  }
}
</script>

<style scoped>
.zenvoice-logo {
  background-color: #4DA87C;
}

table th:first-child {
  border-radius: 6px 0 0 6px;
  width: 28px;
}

table th:last-child {
  border-radius: 0 6px 6px 0;
}

.zenvoice-button {
  padding: 0 !important;
  overflow: hidden;

}

.zenvoice-button>>>.text {
  padding: 0 !important;
}

.warning-banner {
  background-color: #FFF0F3
}

.fade-enter-active,
.fade-leave-active {
  transition: all 150ms;
}

.fade-enter,
.fade-leave-to .fade-leave-active {
  opacity: 0;
  transform: translateY(-1rem);
}

/* Forms */
.form-input {
  color: #06070F;
  border-radius: 6px;
  border: 1px solid;
  border-color: transparent;
  box-shadow: 0px 1px 2px 0px rgba(0, 56, 108, 0.08), 0px 0px 0px 1px rgba(0, 56, 108, 0.08);
  transition: border-color 150ms ease-in-out, box-shadow 150ms ease-in-out;

  /* Body/Small */
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  /* 142.857% */
  height: 32px
}

.form-input:focus {
  outline: none;
  box-shadow: 0px 1px 2px 0px rgba(0, 56, 108, 0.1), 0px 0px 0px 1px rgba(0, 56, 108, 0.1);
}

.form-input::placeholder {
  color: #5E6678;
  transition: color 150ms ease-in-out;
  opacity: 0.9;
}

.form-input.invalid {
  box-shadow: none;
  border-color: #FF002F;
}

.form-input:hover::placeholder {
  color: #303546;
}

.form-input:focus::placeholder {
  color: #C1C7D0;
}

.fade-in {
  animation: fadeIn 150ms ease-in-out;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}
</style>
