// Imports
import firebase from '@/api/config/FirebaseConfig'
import FirebaseAPI from '@/api/firebase'
import API from '@/api'
import router from '../../router'
import store from '../index'

// Auth Module
export default {
  namespaced: true,
  state: {
    user: {},
    subscription: {},
    team: null,
    pendingInvitations: [],
    stripeCustomer: {},
    invoices: [],
    initialLoad: null,
    teamSubscriptionState: null,
    teamSubscription: null,
    getStarredBoards: [],
    showUpsell: false,
    upsellStep: 0
  },
  getters: {
    // Get User object
    getUser: state => state.user,

    // Get initial load state
    getInitialLoad: state => state.initialLoad,

    // Get User full name
    getUserName: state => `${state.user.first_name || ''} ${state.user.last_name || ''}`.trim() || state.user.name || state.user.email,

    // Get User first name
    getUserFirstName: state => state.user.first_name || '',

    // Get User email
    getUserEmail: state => state.user.email,

    // Get User avatar
    getUserAvatar: state => state.user.avatar,

    // Get User token
    getUserToken: state => state.user.token,

    // Get User free trial date
    getUserFreeTrialDate: state => state.user.trialStarted,

    // Check if User is logged in
    isUserLoggedIn: state => state.user.email,

    // Get User Subscription
    getUserSubscription: state => state.subscription,

    // Get User StripeCustomer
    getStripeCustomer: state => state.stripeCustomer,

    // Get User Invoices
    getUserInvoices: state => state.invoices,

    // Check if User is on free tier
    isFreeTier: state => !state.subscription.product || state.subscription.product.name === 'Free',

    // Get Pending Invitations
    getPendingInvitations: state => state.pendingInvitations,

    // Get Team object
    getTeam: state => state.team,

    // Check if User has viewed Survey
    getSurveyViewed: state => state.user.surveyViewed,

    // Current team subscription
    getTeamSubscriptionState: state => state.teamSubscriptionState,

    getTeamSubscription: state => state.teamSubscription,

    getStarredBoards: state => state.getStarredBoards,

    // Check if the user is the team owner
    isTeamOwner: state => state.team && state.user && state.team.created_by === state.user.user_id,

    // Check if we should show the upsell modal in Library
    getShowUpsell: state => state.showUpsell,

    // Get the upsell step
    getUpsellStep: state => state.upsellStep
  },
  mutations: {
    // Set User Mutation
    SET_USER (state, user) {
      state.user = user
    },
    // Set User Mutation
    SET_INITIAL_LOAD (state) {
      state.initialLoad = true
    },
    // Set User Subscription
    SET_USER_SUBSCRIPTION (state, subscription) {
      state.subscription = subscription
    },
    // Set User Invoices
    SET_USER_INVOICES (state, invoices) {
      state.invoices = invoices
    },
    // Set User Subscription
    SET_USER_STRIPE_CUSTOMER (state, customer) {
      state.stripeCustomer = customer
    },
    // Set User Pending Invitations
    SET_USER_INVITATIONS (state, invitations) {
      state.pendingInvitations = invitations
    },
    // Set User Pending Invitations
    SET_USER_TEAM (state, team) {
      state.team = team
    },
    // Set Onboarding Complete
    SET_ONBOARDING_COMPLETE (state) {
      state.user.onboardingComplete = true
    },
    SET_PRODUCTHUNT_MODAL_VIEWED (state) {
      state.user.viewedProductHuntModal = true
    },
    SET_SURVEY_VIEWED (state) {
      state.user = {
        ...state.user,
        surveyViewed: true
      }
    },
    SET_TEAM_SUBSCRIPTION_STATE (state, subscriptionState) {
      state.teamSubscriptionState = subscriptionState
    },
    SET_TEAM_SUBSCRIPTION (state, subscription) {
      state.teamSubscription = subscription
    },
    SET_STARRED_BOARDS (state, boards) {
      state.getStarredBoards = boards
    },
    SET_USER_IG_USERNAME (state, username) {
      state.user.igUsername = username
    },
    SET_USER_IG_BOARD_IDS (state, boardIds) {
      state.user.igBoardIds = boardIds
    },
    SET_SHOW_UPSELL (state, showUpsell) {
      state.showUpsell = showUpsell
    },
    SET_UPSELL_STEP (state, step) {
      state.upsellStep = step
    },
    SET_REMOVE_DEFAULT_LANGUAGES (state) {
      state.user.defaultLanguages = []
    }
  },
  actions: {
    // Login/Signup User With Google
    async loginUserWithGoogle ({ commit, state }) {
      const provider = new firebase.auth.GoogleAuthProvider()

      const result = await firebase.auth().signInWithPopup(provider)

      if (result.additionalUserInfo.isNewUser) {
        if (window.location.pathname.includes('discovery-experts')) {
          window.Intercom('update', {
            from_experts: true
          })
        }
      }

      setTimeout(() => {
        window.rewardful('convert', { email: result.user.email })
      }, 5000)

      await result.user.getIdToken()

      commit('SET_USER', {
        ...state.user,
        email: result.user.email,
        name: result.user.displayName,
        avatar: result.user.photoURL
      })

      // Track the user login event
      await API.Analytics.login({
        email: result.user.email,
        type: 'google',
        utmCampaign: store.getters['MiscModule/getUtmSource'] || '',
        utmMedium: store.getters['MiscModule/getUtmMedium'] || '',
        utmSource: store.getters['MiscModule/getUtmSource'] || ''

      })

      window.analytics.alias(result.user.email)

      authChromeExtension(result.user.user_id)

      router.push({ name: 'DiscoveryView' }).catch(e => e)
    },
    async checkTeamExpired ({ commit, state }) {
      if (!state.team) return

      const createdById = state.team.created_by

      const db = firebase.firestore()
      // Check for active subscriptions
      const teamSubscriptionSnapshot = await db.collection('customers').doc(createdById).collection('subscriptions').where('status', 'in', ['trialing', 'active']).get()

      if (teamSubscriptionSnapshot.empty) {
        console.log('No active subscriptions')
        // Get and sort cancelled subscriptions
        const inactiveSubscriptionsSnapshot = await db.collection('customers').doc(createdById).collection('subscriptions').where('status', 'in', ['canceled']).get()
        const teamInactiveSubscriptions = inactiveSubscriptionsSnapshot.docs.map(doc => doc.data()).sort((a, b) => b.canceled_at.seconds - a.canceled_at.seconds)

        // Get past dude subscriptions (they will need to be reactivated)
        const pastDueSubscriptionsSnapshot = await db.collection('customers').doc(createdById).collection('subscriptions').where('status', 'in', ['past_due']).get()

        // If there aren't any past due subscriptions, use the most recent cancelled subscription
        if (pastDueSubscriptionsSnapshot.empty) {
          // Set as active for trial users who are on a team
          if (teamInactiveSubscriptions.length === 0) {
            // We have to check if the team owner is on a free trial or not if the trial started in the last 7 days
            const teamOwner = await db.collection('auth-users').doc(createdById).get()
            const trialStarted = teamOwner.data().trialStarted

            if (trialStarted && Date.now() - trialStarted < 604800000) {
              commit('SET_TEAM_SUBSCRIPTION_STATE', 'active')
            } else {
              commit('SET_TEAM_SUBSCRIPTION_STATE', 'inactive')
            }

            return
          }

          // Use the most recent subscription
          const mostRecentSubscription = teamInactiveSubscriptions[0]
          commit('SET_TEAM_SUBSCRIPTION_STATE', mostRecentSubscription.status)
          return
        } else {
          const mostRecentSubscription = pastDueSubscriptionsSnapshot.docs[0]
          commit('SET_TEAM_SUBSCRIPTION_STATE', mostRecentSubscription.data().status)
          return
        }
      }

      commit('SET_TEAM_SUBSCRIPTION_STATE', 'active')
    },
    async fetchFreeTrialDate ({ getters, commit }) {
      const userId = firebase.auth().currentUser
      const stateUser = getters.getUser
      const user = await firebase.firestore().collection('auth-users').doc(userId.uid).get()
      const userData = user.data()
      commit('SET_USER', {
        ...userData,
        ...stateUser,
        user_id: getters.getUser?.user_id,
        trialStarted: userData.trialStarted
      })
    },
    // Sign Up with Email and Password
    async signUpWithEmailAndPassword ({ commit, state }, creds) {
      const result = await firebase
        .auth()
        .createUserWithEmailAndPassword(creds.email, creds.password)

      await result.user.getIdToken()

      await result.user.updateProfile({
        displayName: creds.name
      })

      const userPayload = {
        first_name: creds.firstName,
        email: result.user.email,
        name: creds.name,
        trialStarted: Date.now()
      }

      if (creds.lastName) {
        userPayload.last_name = creds.lastName
      }

      await firebase.firestore().collection('auth-users').doc(result.user.uid).set(userPayload, { merge: true })

      setTimeout(() => {
        window.rewardful('convert', { email: creds.email })
      }, 5000)

      commit('SET_USER', {
        ...state.user,
        email: result.user.email,
        name: result.user.displayName,
        avatar: result.user.photoURL
      })

      // company_category
      // window.Intercom('update', {
      //   company_category: creds.companyType
      // })

      window.analytics.alias(result.user.email)

      if (window.location.pathname.includes('discovery-experts')) {
        window.Intercom('update', {
          from_experts: true
        })
      }

      authChromeExtension(result.user.user_id)

      router.push({ name: 'DiscoveryView' })
    },
    // Log In With Password
    async loginUserWithEmailAndPassword ({ commit, state }, creds) {
      const result = await firebase.auth().signInWithEmailAndPassword(creds.email, creds.password)
      await result.user.getIdToken()

      commit('SET_USER', {
        ...state.user,
        email: result.user.email,
        name: result.user.displayName,
        avatar: result.user.photoURL
      })

      window.analytics.alias(result.user.email)

      authChromeExtension(result.user.user_id)

      router.push({ name: 'DiscoveryView' })
    },
    // Login With Token
    async loginUserWithToken ({ commit, state }, creds) {
      const result = await firebase.auth().signInWithCustomToken(creds.token)
      await result.user.getIdToken()

      commit('SET_USER', {
        ...state.user,
        email: result.user.email,
        name: result.user.displayName,
        avatar: result.user.photoURL
      })

      router.push({ name: 'DiscoveryView' })
    },
    // Logout User
    async logoutUser () {
      await firebase
        .auth()
        .signOut()

      // find the current page location and remove '/' from the pathname
      const currentLocation = window.location.pathname.replace('/', '')

      // track the user logout event
      await window.analytics.track('User Logged Out', {
        pageLocation: currentLocation
      })
      router.push({ name: 'LoginView' })
    },
    async getStripeCustomer ({ commit }, user) {
      const db = firebase.firestore()
      const stripeCustomer = await db.collection('customers').doc(user.uid).get()
      commit('SET_USER_STRIPE_CUSTOMER', stripeCustomer.data())

      return stripeCustomer
    },
    async getUserInvoices ({ commit, state }, user) {
      const db = firebase.firestore()

      if (!state.subscription || !state.subscription.id) {
        commit('SET_USER_INVOICES', [])
      }

      const snapshot = await db.collection('customers')
        .doc(user.uid)
        .collection('subscriptions').doc(state.subscription.id).collection('invoices').get()

      const invoices = snapshot.docs?.map(doc => {
        return {
          id: doc.id,
          ...doc.data() || []
        }
      })

      commit('SET_USER_INVOICES', invoices)
    },
    // Set User Subscription
    setUserSubscription ({ commit, state }, user) {
      return new Promise((resolve, reject) => {
        let subscription = {}

        const db = firebase.firestore()
        db.collection('customers')
          .doc(user.uid)
          .collection('subscriptions')
          .where('status', 'in', ['trialing', 'active', 'past_due'])
          .onSnapshot(async doc => {
            if (doc.docs.length) {
              const data = doc.docs[0].data()

              subscription = {
                ...data,
                id: doc.docs[0].id,
                price: await data.price.get().then(e => e.data()),
                product: await data.product.get().then(e => e.data())
              }

              commit('SET_USER_SUBSCRIPTION', subscription)

              resolve(subscription)
            } else {
              resolve({})
            }
          })
      })
    },
    // Set Team subscription
    async setTeamSubscription ({ commit, state }) {
      const db = firebase.firestore()

      const team = state.team
      if (team) {
        const teamSubscriptionSnapshot = await db.collection('customers').doc(team?.created_by).collection('subscriptions').where('status', 'in', ['trialing', 'active']).get()
        if (teamSubscriptionSnapshot.docs.length > 0) {
          const data = teamSubscriptionSnapshot.docs[0].data()
          const subscription = {
            ...data,
            id: teamSubscriptionSnapshot.docs[0].id,
            price: (await data.price.get()).data(),
            product: (await data.product.get()).data()
          }
          commit('SET_TEAM_SUBSCRIPTION', subscription)
        }
      }
    },
    async fetchUserInvitations ({ commit }) {
      const userInvitations = await FirebaseAPI.Invitations.getUserInvitations()
      commit('SET_USER_INVITATIONS', userInvitations)
    },
    async fetchUserTeam ({ commit }) {
      const userTeam = await FirebaseAPI.Teams.getUserTeam()

      // Check if the user/team has any outgoing invitations
      if (userTeam) {
        const userInvitations = await FirebaseAPI.Invitations.getAllCreatedByUser(userTeam.id)
        userTeam.outgoingInvitations = userInvitations.length
      }

      commit('SET_USER_TEAM', userTeam)

      return userTeam
    }
  }
}

async function authChromeExtension (userId) {
  try {
    const createTokenFunction = firebase.functions().httpsCallable('createToken')
    const token = await createTokenFunction(userId)

    // eslint-disable-next-line no-undef
    chrome.runtime.sendMessage('eaancnanphggbfliooildilcnjocggjm', {
      event: 'auth',
      data: { token }
    }, function (response) {
      if (response.status === 'worked') {
        window.Intercom('update', {
          installed_extension: true
        })
      }
    })

    // So this doesnt break for people witht he old ext
    try {
      // Also send this token to the plugin to authenticate the user
      const root = document.querySelector('#FP-PLUGIN')
      const iframe = root?.shadowRoot?.querySelector('.FP-plugin-iframe')
      if (iframe) {
        console.log('posting message')
        iframe.contentWindow.postMessage({
          source: 'fp:app',
          type: 'auth',
          data: { token }
        }, '*')
      }
    } catch (error) {
      console.log(error)
    }
  } catch (error) {
    console.log(error)
  }
}
