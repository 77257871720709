import Ads from './AdsRequests'
import Brands from './BrandsRequests'
import Screenshots from './ScreenshotsRequests'
import Search from './SearchRequests'
import Users from './UsersRequests'
import Campaigns from "./CampaignRequests"

export default {
  Ads,
  Brands,
  Screenshots,
  Search,
  Users,
  Campaigns
}
